<template>
  <article class="formulas">
    <section>
      <header >
        <h3>Servicio farmacéutico Rionegro - Bandeja de archivos{{ addTitle }}</h3>
        <article v-if="isAdmin">
          <div>
            
            <v-select
              v-model="bodega"
              :items="bodegas"
              label="Seleccione Bodega"
              outlined
              @change="handleStatusChange"
            ></v-select>
          </div>
          <div>
          </div>
          <div class="calendar">
          </div>
        </article>      
      </header>
      <section>
        <ul>
          <li v-for="(formula, index) in formulas" :key="index" @click="redirectToDigitalizador(formula)">
            <div class="check">
              <v-icon class="me-2">check</v-icon>
            </div>
            <header>
              <!-- <h4>{{ formula.codigoBodega }}</h4> -->
              <h5>{{ formula.fechaHora.substring(0,formula.fechaHora.indexOf(" ")) }}</h5>
              <h5>{{ formula.fechaHora.substring(formula.fechaHora.indexOf(" "),formula.fechaHora.length) }}</h5>
              <figure><v-icon class="me-2">picture_as_pdf</v-icon></figure>
            </header>
          </li>        
        </ul>
      </section>
    </section>
    <div class="loading" v-if="loading">
      <div class="loadingio-spinner-spinner-2by998twmg8"><div class="ldio-yzaezf3dcmj">
        <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
        </div></div>
        <h4>Cargando Bandeja...</h4>
    </div>  
  </article>
</template>

<script>
import { mapState, mapMutations } from "vuex";


export default {
  name: 'Index',
  data: () => ({
    tab: null,
    rolesKeycloak: null,
    codigosEmpresas: [],
    dataEmpresas: [],
    loading: true,
    formulas: [
      // {
      //   fechaHora: "20-04-24 20:15"
      // },
      // {
      //   fechaHora: "21-04-24 20:15"
      // },
      // {
      //   fechaHora: "22-04-24 20:15"
      // },
    ],
    usuario: "",
    bodega: "",
    afiliado: "",
    fecha: "",
    fecha2: "",    
    isAdmin: false,
    bodegas: [
      'Bodega 1',
      'Bodega 2',
      'Bodega 3',
      'Bodega 4',
    ],

    addTitle:"",

    menu: false,
    menu2: false,

    timeoutId: null
  }),
  mounted() {
    this.usuario = this.auth.username;
    this.empresasUsuario();    
    this.startPolling();

    const vuexState = JSON.parse(localStorage.getItem('vuex'));
    
    if(vuexState.auth.roles.includes("Expediente_Administrador")){
      this.isAdmin=true;
    }
    if(vuexState.auth.roles.includes("Expediente_Digitalizador")){
      this.addTitle=" - Digitalizador"
    }
    if(vuexState.auth.roles.includes("Expediente_Auditor")){
      this.addTitle=" - Auditor"
    }
    if(vuexState.auth.roles.includes("Expediente_Radicador")){
      this.addTitle=" - Radicador"
    }
    
  },
  computed: {
    ...mapState(["auth", "notify", "busy", "enterprise"]),
  },
  methods: {
    ...mapMutations([
      "showSuccess",
      "showError"
    ]),
    startPolling() {
      const poll = () => {
        if (this.$route.path.includes('bandeja')) {
          this.getFormulas(); 
        }
        this.timeoutId = setTimeout(poll, 3000); 
      };
      poll(); 
    },
    stopPolling() {
      clearTimeout(this.timeoutId);
    },
    getFormulas() {      
            this.$http.get(`/msa-process-file/api/file/listarBandeja`)
        .then(({ data }) => {
          console.log(data);    
          this.loading =false;   
          this.formulas = data
        })
        .catch(err => console.log(err));        
    },
    async empresasUsuario() {
      const codEmpresas = await this.$http.get(`msa-setting/api/usem/relacion/${this.usuario}`)
        .then(({ data }) => this.codigosEmpresas = data)
        .catch(err => console.log(err))

      if (codEmpresas != []) {
        await this.$http.post('msa-setting/api/empresa/list', this.codigosEmpresas)
          .then(({ data }) => this.dataEmpresas = data)
          .catch(err => console.log(err))
      }
    },
    toEnterprise(enterprise) {
      this.auth.roles = this.rolesKeycloak;
      this.setEnterprise(enterprise)
    },
    redirectToDigitalizador(archivo) {
      console.log(archivo);
      console.log(this.timeoutId);
      if (this.timeoutId !== null) {
        clearInterval(this.timeoutId);
        this.timeoutId = null;
      }
      let name= archivo.rutaCompleta.split("/").join("-");

      // let data={
      //   name
      // }
      // const queryParams = data ? `?name=${name}` : '';
      // const redirectUrl = '/#/modules/expediente/digitalizador';
      // window.location.href = `${redirectUrl}${queryParams}`;
      // window.location.reload();


      this.$router.push({ 
        name: 'modules.expedienteMain.digitalizador', 
        query: { name: name }  
      }); 
      
      // let name= archivo.rutaCompleta.split("/").join("-");
      // const link = document.createElement('a');
      //   link.href = '/modules/expediente/digitalizador?name='+ name;
      //   document.body.appendChild(link);
      //   link.click();
      //   document.body.removeChild(link);
      //   URL.revokeObjectURL(href);
    },
    beforeDestroy() {
      this.stopPolling();
    },

  }
}
</script>

<style scoped>

ul, ol {
  list-style: none;
}

article.formulas{
  width: 100%;
}
article.formulas > section{
  display: -webkit-box;
  display: -ms-flexbox;  
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
}

article.formulas > section> header {
  padding: 1rem 0;  
  background: white;
  -webkit-box-shadow: 2px 2px 5px -1px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 2px 2px 5px -1px rgba(0, 0, 0, 0.23);
  box-shadow: 2px 2px 5px -1px rgba(0, 0, 0, 0.23);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%
}

article.formulas > section> header h3 {
  padding-left: 1rem;
  margin-bottom: 1rem;
  text-align: left;
  color: #0D47A1;
  width: 90%;
}

article.formulas > section> header .calendar {  
  position: relative;  
}

article.formulas > section> header .calendar figure{  
  position: absolute;
  right: 10px;
  top: 15px;
}

article.formulas > section> header > article {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 90%;
  margin-top: 10px;
}

article.formulas > section> header > article div {
  margin: 0 1rem;
  width: 100%;
}

article.formulas > section> header > article div:first-child > div {
  margin-top: -22px;
}

article.formulas > section> header > article div label {
  margin-left: .5rem;
  width: 100%;
}

article.formulas > section> section {
  width: 100%;
}
article.formulas > section> section > ul{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;  
}

article.formulas > section> section > ul li{
  aspect-ratio: 1 / 1;
  background: white;
  border-radius: 1rem;
  position: relative;
  cursor: pointer;
  padding: 1%;
  margin: 2%;
  width: 15%;
  min-width: 100px;
  overflow: hidden;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
}

article.formulas > section> section > ul li > header{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;  
  height: 100%;
}

article.formulas > section> section > ul li > header h4{
  text-align: center;
  width: 100%;
}
article.formulas > section> section > ul li > header h5{
  text-align: center;
  white-space: nowrap;
  width: 100%;
  font-size: 15px;
}

article.formulas > section> section > ul li > header figure{
  margin-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
}

article.formulas > section> section > ul li > header figure i{  
  font-size: 30px;
  color: #FF5733;;
}

article.formulas > section> section > ul li > .check{
  margin-top: 1rem;
  background-color: white;
  display: none;
  position: absolute;
  bottom: -10px;
  right: -10px;
}
article.formulas > section> section > ul li > .check i{
  font-size: 1.2rem;
  color: green;
  padding: .2rem;
  margin-right: 0 !important;
}

article.formulas > section> section > ul li.completa > .check{
  margin-top: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid green;
  border-radius: 50%;
}

.loading{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(100vh - 53px);
  background-color: rgba(0,0,0,.5);
  z-index: 2;
}
.loading h4{
  width: 100%;
  text-align: center;
  color: white;
  font-size: 19px;
  margin-top: -20px;
  font-weight: 200;
}

@keyframes ldio-yzaezf3dcmj {
  0% { opacity: 1 }
  100% { opacity: 0 }
}
.ldio-yzaezf3dcmj div {
  left: 96px;
  top: 45px;
  position: absolute;
  animation: ldio-yzaezf3dcmj linear 1s infinite;
  background: #0d47a1;
  width: 8px;
  height: 18px;
  border-radius: 3.42px / 3.42px;
  transform-origin: 4px 55px;
}.ldio-yzaezf3dcmj div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.9375s;
  background: #0d47a1;
}.ldio-yzaezf3dcmj div:nth-child(2) {
  transform: rotate(22.5deg);
  animation-delay: -0.875s;
  background: #0d47a1;
}.ldio-yzaezf3dcmj div:nth-child(3) {
  transform: rotate(45deg);
  animation-delay: -0.8125s;
  background: #0d47a1;
}.ldio-yzaezf3dcmj div:nth-child(4) {
  transform: rotate(67.5deg);
  animation-delay: -0.75s;
  background: #0d47a1;
}.ldio-yzaezf3dcmj div:nth-child(5) {
  transform: rotate(90deg);
  animation-delay: -0.6875s;
  background: #0d47a1;
}.ldio-yzaezf3dcmj div:nth-child(6) {
  transform: rotate(112.5deg);
  animation-delay: -0.625s;
  background: #0d47a1;
}.ldio-yzaezf3dcmj div:nth-child(7) {
  transform: rotate(135deg);
  animation-delay: -0.5625s;
  background: #0d47a1;
}.ldio-yzaezf3dcmj div:nth-child(8) {
  transform: rotate(157.5deg);
  animation-delay: -0.5s;
  background: #0d47a1;
}.ldio-yzaezf3dcmj div:nth-child(9) {
  transform: rotate(180deg);
  animation-delay: -0.4375s;
  background: #0d47a1;
}.ldio-yzaezf3dcmj div:nth-child(10) {
  transform: rotate(202.5deg);
  animation-delay: -0.375s;
  background: #0d47a1;
}.ldio-yzaezf3dcmj div:nth-child(11) {
  transform: rotate(225deg);
  animation-delay: -0.3125s;
  background: #0d47a1;
}.ldio-yzaezf3dcmj div:nth-child(12) {
  transform: rotate(247.5deg);
  animation-delay: -0.25s;
  background: #0d47a1;
}.ldio-yzaezf3dcmj div:nth-child(13) {
  transform: rotate(270deg);
  animation-delay: -0.1875s;
  background: #0d47a1;
}.ldio-yzaezf3dcmj div:nth-child(14) {
  transform: rotate(292.5deg);
  animation-delay: -0.125s;
  background: #0d47a1;
}.ldio-yzaezf3dcmj div:nth-child(15) {
  transform: rotate(315deg);
  animation-delay: -0.0625s;
  background: #0d47a1;
}.ldio-yzaezf3dcmj div:nth-child(16) {
  transform: rotate(337.5deg);
  animation-delay: 0s;
  background: #0d47a1;
}
.loadingio-spinner-spinner-2by998twmg8 {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
}
.ldio-yzaezf3dcmj {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-yzaezf3dcmj div { box-sizing: content-box; }

</style>